<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="formattedTasks"
      :items-per-page="10"
      class="mt-2 elevation-1"
    ><!-- :item-class="defineTaskClass"-->
      <template v-slot:[`item.tsk_id`]="{ item }">
        {{ getShortTaskName(item) }}
      </template>
      <template v-slot:[`item.tsk_eve_id`]="{ item }">
        <span v-if="item.tsk_eve_id">
          <v-btn class="ma-0" text icon color="amber" @click="openEvent(item.tsk_eve_id)"><v-icon>mdi-alert</v-icon></v-btn>
        </span>
      </template>
      <template v-slot:[`item.types`]="{ item }">
        <ActionLabel v-for="action in item.types" :key="action.typ_id" :action="action" />
      </template>
      <template v-slot:[`item.tsk_sta_id`]="{ item }">
        <v-chip label outlined :color="item.state.sta_color">{{ item.state.sta_label }}</v-chip>
      </template>
      <template v-slot:[`area.are_identifier`]="{ item }">
        <span>{{ (item.area) ? item.area.are_identifier : '' }}</span>
      </template>
      <template v-slot:[`item.tsk_custom`]="{ item }">
        <v-simple-checkbox disabled :value="item.tsk_custom === 1" />
      </template>
      <template v-slot:[`item.tsk_started_at`]="{ item }">
        <v-chip label outlined>{{ locDateFormat.toLocale(item.tsk_started_at) }}</v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn label outlined small @click="edit(item)">
          <v-icon v-if="permissions.tasks.update">mdi-pencil</v-icon>
          <v-icon v-else>mdi-eye</v-icon>
        </v-btn>
      </template>
      <template v-slot:no-data>
        <v-alert :value="!tasks.length" color="error" icon="mdi-alert" class="ma-3">
          Aucune tâche
        </v-alert>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import dateLocale from '@/services/dateLocale'
import TaskMixin from '@/mixins/TaskMixin'
import ActionLabel from '@/components/tasks/ActionLabel'
import { bus } from '@/plugins/bus'

export default {
  mixins: [TaskMixin],
  components: {
    ActionLabel
  },
  props: {
    tasks: {
      type: Array,
      required: true
    }
  },
  data: function () {
    return {
      headers: [
        { text: 'N°', value: 'tsk_id', align: 'left' },
        { text: '-',   value: 'tsk_eve_id', align: 'left' },
        { text: 'Date', value: 'tsk_started_at', align: 'left' },
        { text: 'Types', value: 'types', cellClass: 'd-flex align-center flex-wrap overflow-auto', width: '300' },
        { text: 'Etat', value: 'tsk_sta_id' },
        { text: 'Aire', value: 'area.are_identifier' },
        { text: 'Spéciale', value: 'tsk_custom' },
        { text: 'Actions', value: 'actions' } // + icon telecharger bon de commande
      ],
      locDateFormat: dateLocale,
    }
  },
  methods: {
    async edit (item) {
      item = await this.fetchTask(item.tsk_id)
      this.setTask(item)
    },
    async openEvent(id){
      bus.$emit('event:open', { eve_id: id })
    }
  },
  beforeDestroy() {
      bus.$emit('off')
  }
}
</script>

<template>
  <v-container fluid>
    <PageHeader title="Suivi des travaux" :contextStyle="contextStyle">
      <template #right>
        <v-btn small @click="exportFile">
          <v-icon small color="success">mdi-file-excel</v-icon>
        </v-btn>
      </template>
    </PageHeader>
    <Loader :loading="loading" />
    <Error :show="false" text="Erreur lors du chargement des travaux." />
    <Alert :show="error"  outlined text="Erreur lors du chargement des travaux." />
    <v-row>
      <v-col cols="12">
        <TasksList :tasks="formattedTasks" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from '@/components/ui/PageHeader'
import TasksList from '@/components/tasks/TasksList'
import Loader from '@/components/ui/Loader'
import TaskMixin from '@/mixins/TaskMixin'
import Alert from '@/components/ui/Alert'
import Error from '@/components/ui/Error'
import { filterUpdateService } from '@/rxjsServices';

export default {
  props: ['contextStyle'],
  mixins: [TaskMixin],
  components: {
    TasksList,
    PageHeader,
    Loader,
    Alert,
    Error
  },
  data () {
    return {
      loading: false,
      error: false,
      tasks: []
    }
  },
  async mounted () {
    await this.loadData()
    this.filterService = filterUpdateService.getFilter().subscribe(message => {
        if (message) {
          this.loadData()
        }
    })
  },
  methods: {
    exportFile() {
      alert('export file')
    },
    async loadData () {
      try {
        this.tasks = []
        this.error = false
        this.loading = true
        let filter   = this.filterToUrl
        filter      += (this.filterToUrl) ? '&include=area' : '?include=area'
        this.tasks = await this.fetchTasks(filter)
      } catch (err) {
        this.error = true
      } finally {
        this.loading = false
      }
    },
    beforeDestroy() {
      this.filterService.unsubscribe()
    }
  }
}
</script>

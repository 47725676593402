var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"mt-2 elevation-1",attrs:{"headers":_vm.headers,"items":_vm.formattedTasks,"items-per-page":10},scopedSlots:_vm._u([{key:"item.tsk_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getShortTaskName(item))+" ")]}},{key:"item.tsk_eve_id",fn:function(ref){
var item = ref.item;
return [(item.tsk_eve_id)?_c('span',[_c('v-btn',{staticClass:"ma-0",attrs:{"text":"","icon":"","color":"amber"},on:{"click":function($event){return _vm.openEvent(item.tsk_eve_id)}}},[_c('v-icon',[_vm._v("mdi-alert")])],1)],1):_vm._e()]}},{key:"item.types",fn:function(ref){
var item = ref.item;
return _vm._l((item.types),function(action){return _c('ActionLabel',{key:action.typ_id,attrs:{"action":action}})})}},{key:"item.tsk_sta_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","outlined":"","color":item.state.sta_color}},[_vm._v(_vm._s(item.state.sta_label))])]}},{key:"area.are_identifier",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s((item.area) ? item.area.are_identifier : ''))])]}},{key:"item.tsk_custom",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":"","value":item.tsk_custom === 1}})]}},{key:"item.tsk_started_at",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","outlined":""}},[_vm._v(_vm._s(_vm.locDateFormat.toLocale(item.tsk_started_at)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"label":"","outlined":"","small":""},on:{"click":function($event){return _vm.edit(item)}}},[(_vm.permissions.tasks.update)?_c('v-icon',[_vm._v("mdi-pencil")]):_c('v-icon',[_vm._v("mdi-eye")])],1)]}},{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"ma-3",attrs:{"value":!_vm.tasks.length,"color":"error","icon":"mdi-alert"}},[_vm._v(" Aucune tâche ")])]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }